import moment from 'moment';

export const displayStreakNumber = (streak) => {
	if (streak && streak === 'no-streak-yet') return 0;
	if (streak && streak.streak) {
		if (moment().isAfter(streak.expire_streak_time)) return 0; // if today is after expire_streak_time

		return streak.streak;
	}
	return null;
};
