<template>
	<v-card v-if="streak" class="shadow-blue pa30">
		<h2>Assiduité</h2>
		<p class="ortho-text-gray">Ta productivité orthographique s'améliore de jour en jour, sois régulier</p>
		<p class="wording-streak ortho-text-green text-center font-weight-black">
			<span> <span class="mr-1">🔥</span>{{ streakNumber }} </span>
			<span>jour<span v-if="streakNumber > 1" style="font-size: 20px">s</span> d'affilée</span>
		</p>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { displayStreakNumber } from '@/utils/streak.js';

export default {
	name: 'StreakInExerciseResult',
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		...mapGetters('streak', { streak: 'streak' }),
		streakNumber() {
			return displayStreakNumber(this.streak);
		},
	},
};
</script>

<style lang="scss" scoped>
.wording-streak {
	span:first-child {
		font-size: 30px;
	}
	span:nth-child(2) {
		font-size: 20px;
	}
}
</style>
