<template>
	<div>
		<!-- Component use to create end of parcours Orthographiq  -->
	</div>
</template>

<script>
import { exerciseIsEndOfWhat } from '@/core/exercise-end-of.js';

export default {
	name: 'CreateTestComponent',
	props: {
		formationProgress: Object,
		categoryId: String,
	},

	mounted() {
		const _notRedoExercise = this.formationProgress.formation.last_exercice === this.$route.params.idExercice;

		const _exerciseIsEndOf = exerciseIsEndOfWhat(
			this.formationProgress,
			this.categoryId,
			this.$route.params.idExercice
		);

		const parcoursIdOfThisExercise = this.findParcoursIdInFormationConfig(this.categoryId);

		if (_notRedoExercise === true && _exerciseIsEndOf === 'end-of-parcours') {
			this.createEndParcoursTest(this.formationProgress.formation.user_id, parcoursIdOfThisExercise);
		} else if (_notRedoExercise === true && _exerciseIsEndOf === 'end-of-formation') {
			this.createEndParcoursTest(this.formationProgress.formation.user_id, parcoursIdOfThisExercise);
		}
	},
	methods: {
		async createEndParcoursTest(userId, parcoursId) {
			await this.$store.dispatch('tests/createEndParcoursTest', { userId, parcoursId });
		},
		findParcoursIdInFormationConfig(categoryId) {
			let parcoursId = null;
			this.formationProgress.config.parcours_list.forEach((parcoursItem) => {
				const foundCategory = parcoursItem.parcours_id.categories.find((category) => category._id === categoryId);
				if (foundCategory != null) parcoursId = parcoursItem.parcours_id._id;
			});

			return parcoursId;
		},
	},
};
</script>

<style lang="scss" scoped></style>
