import { ParcoursItemStatus } from '@/constants/parcours.js';
import { CategoryStatus } from '@/constants/category';

const isLastCategoryOfParcours = (parcours) => {
	// Because category can be done without order. Therefore, last category of parcour is if number of has been category + 1 == total category of parcours
	const _totalPassedCategory = parcours.categories.filter((category) => category.status === CategoryStatus.COMPLETED)
		.length;
	return _totalPassedCategory + 1 === parcours.categories.length;
};

const isLastParcoursOfFormation = (parcoursListItems) => {
	const _lockedParcoursItem = parcoursListItems.filter((item) => item.status === ParcoursItemStatus.locked).length;
	if (_lockedParcoursItem === 0) return true;
	return false;
};

export const exerciseIsEndOfWhat = (formationProgress, categoryId, exerciseId) => {
	let _thisCategory = null;
	let _isThisLastCatOfParcours = false;

	formationProgress.config.parcours_list.forEach((parcoursItem) => {
		const _foundCategory = parcoursItem.parcours_id.categories.find((category) => category._id === categoryId);
		if (_foundCategory != null) {
			_thisCategory = _foundCategory;
			_isThisLastCatOfParcours = isLastCategoryOfParcours(parcoursItem.parcours_id);
		}
	});
	if (_thisCategory == null) return null;

	const _thisExercise = _thisCategory.exercises.find((exercise) => exercise._id === exerciseId);
	if (_thisExercise == null) return null;

	const _isThisLastExerciseOfCategory = _thisExercise.position_list === _thisCategory.exact_nb_exos;
	const _isLastParcoursOfFormation = isLastParcoursOfFormation(formationProgress.config.parcours_list);

	if (_isThisLastExerciseOfCategory && _isThisLastCatOfParcours && _isLastParcoursOfFormation)
		return 'end-of-formation';
	else if (_isThisLastExerciseOfCategory && _isThisLastCatOfParcours) return 'end-of-parcours';
	else if (_isThisLastExerciseOfCategory) return 'end-of-category';
	else return 'end-normal-exercise';
};
